import { QuiBadge, QuiBox, QuiButton, QuiIcon, QuiIconEnum, QuiText } from '@tonicai/ui-quinine';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { useMemo, useState } from 'react';
import { FileAdder } from '@/components/FileAdder/FileAdder';
import { DatasetTemplatesButton } from '@/components/Templates/DatasetTemplatesButton';
import { currentUserAtom } from '@/stores/auth';
import { useIsOcrEnabled } from '@/stores/settings';
import { Dataset, JobStatus } from '@/types';
import { useFilePreviewContext } from '@/components/FilePreviews/useFilePreviewContext';
import styles from './DatasetInfo.module.scss';
import { DatasetInfoMenu } from './DatasetInfoMenu';
import { FileIcon } from '@/components/FileIcon/FileIcon';
import { client } from '@/services/HTTPClient';
import { usePolling } from '@/hooks/usePolling';
import { useForm } from 'react-final-form';
import { fetchFilesNeedingRescan, useFilesNeedingRescan } from '@/stores';

type DatasetInfoProps = Readonly<{
    dataset: Dataset;
}>;

export function DatasetInfo({ dataset }: DatasetInfoProps) {
    const { openPreview } = useFilePreviewContext();
    const user = useAtomValue(currentUserAtom);
    const ocrEnabled = useIsOcrEnabled();
    const [isScanRunning, setIsScanRunning] = useState(false);
    const form = useForm();
    const filesNeedingRescan = useFilesNeedingRescan(dataset.id);

    const hasAtLeastOnePDF = useMemo(() => {
        return dataset.files.find((f) => f.fileType === 'Pdf') !== undefined;
    }, [dataset.files]);

    const hasAnyFiles = useMemo(() => dataset.files.length > 0, [dataset.files]);
    const canRescanFiles = !isScanRunning && hasAnyFiles && !!filesNeedingRescan?.length;

    usePolling(
        async () => {
            const response = await client.get(`/api/dataset/${dataset.id}/jobs/is_running`);
            setIsScanRunning(!!response.data?.isRunning);
            await fetchFilesNeedingRescan(dataset.id);
        },
        true,
        1000
    );

    return (
        <QuiBox overflow="hidden" borderRadius="md" border="white-600">
            <QuiBox bg="white" borderBottom="white-600" padding="md" gap="md" flexDirection="column" display="flex">
                <QuiBox display="flex" alignItems="center" gap="sm">
                    <QuiBadge iconLeft="message-square" size="xl" variant="purple" />
                    <QuiText size="text-xl" weight="bold">
                        {dataset.name}
                    </QuiText>
                </QuiBox>
                <QuiBox display="flex" flexDirection="column" gap="sm">
                    <div>
                        <QuiBox text="text-xs" color="black-600">
                            {`Edited ${dayjs(dataset.lastUpdated).fromNow()} by ${user?.displayName ?? user?.userName}`}
                        </QuiBox>
                    </div>
                </QuiBox>
                {ocrEnabled && hasAtLeastOnePDF && (
                    <QuiBox>
                        <DatasetTemplatesButton datasetId={dataset.id} />
                    </QuiBox>
                )}
                <QuiBox display="flex" flexDirection={'column'} gap="sm">
                    <QuiBox display="flex" gap="sm">
                        <DatasetInfoMenu dataset={dataset} />
                    </QuiBox>
                </QuiBox>
            </QuiBox>

            <QuiBox display="flex" gap="sm" flexDirection="column">
                <QuiBox display="flex" flexDirection="column" gap="sm" padding="md">
                    {canRescanFiles && (
                        <QuiBox bg="orange-50" padding="sm" text="text-sm" display="flex" alignItems="start" gap="sm">
                            <div>
                                <QuiIcon size="md" color="orange-600" icon={QuiIconEnum.Info} />
                            </div>
                            <QuiBox>
                                Scan files to apply changes.
                                <QuiBox text="text-xs" color="brand-black-600">
                                    A file scan is required to apply recent changes to your files.
                                </QuiBox>
                                <QuiButton
                                    onClick={() => {
                                        form.change('shouldRescan', true);
                                        form.submit()?.then(() => setIsScanRunning(true));
                                    }}
                                >
                                    Scan
                                </QuiButton>
                            </QuiBox>
                        </QuiBox>
                    )}
                    {isScanRunning && (
                        <QuiBox
                            border="white-600"
                            text="text-sm"
                            display="flex"
                            justifyContent="space-between"
                            borderRadius="md"
                            alignItems="center"
                            padding="sm"
                            bg="white"
                        >
                            <QuiBox display="flex" gap="sm" alignItems="center">
                                <FileIcon jobStatus={JobStatus.RUNNING} /> Scanning files...
                            </QuiBox>
                            <QuiButton
                                onClick={() => {
                                    client.post(`/api/dataset/${dataset.id}/jobs/cancel/all`);
                                }}
                            >
                                Cancel
                            </QuiButton>
                        </QuiBox>
                    )}

                    <QuiBox display="flex" alignItems="stretch">
                        <QuiButton className={styles.fullWidth} iconLeft="folder" type="button" onClick={openPreview}>
                            Preview and Manage Files
                        </QuiButton>
                    </QuiBox>
                </QuiBox>
                <FileAdder mode="combobox" name="fileIds" datasetId={dataset.id} />
            </QuiBox>
        </QuiBox>
    );
}
