import { PiiTypeEnum, PiiTypeInfo } from '@/types';
import { QuiBox, QuiButton, QuiIcon, QuiIconEnum, QuiSegmentedControlButton, QuiSegmentedControlField } from '@tonicai/ui-quinine';
import { RegexListFieldModal } from './RegexListFieldModal';
import { piiTypeMetadataComponents } from './RedactTypeOptions';
import { useField, useForm } from 'react-final-form';
import { useEffect, useRef, useState } from 'react';
import fastDeepEqual from 'fast-deep-equal';

export default function UndetectedEntity({ entity }: { entity: PiiTypeInfo }) {
    const [showOptions, setShowOptions] = useState(false);
    const form = useForm();
    const MetadataComponent = entity.value in piiTypeMetadataComponents ? piiTypeMetadataComponents[entity.value as PiiTypeEnum] : null;
    const {
        input: { value: generatorSetupValue },
    } = useField(`generatorSetup.${entity.value}`);
    const {
        input: { value: datasetGeneratorMetadataValue },
    } = useField('datasetGeneratorMetadata');
    const generatorSetupValueRef = useRef(generatorSetupValue);
    useEffect(() => {
        if (!fastDeepEqual(generatorSetupValueRef.current, generatorSetupValue)) {
            form.submit();
        }

        generatorSetupValueRef.current = generatorSetupValue;
    }, [generatorSetupValue, form, datasetGeneratorMetadataValue]);
    return (
        <QuiBox padding="none md" key={entity.label}>
            <QuiBox padding="sm none" borderTop="white-600">
                <QuiBox display="flex" alignItems="center" justifyContent="space-between">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 10,
                        }}
                    >
                        <QuiIcon icon={entity.icon ?? 'help-circle'} />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <span className={'pii-type-label'}>{entity.label}</span>
                            <span className={'pii-type-description'}>{entity.description}</span>
                        </div>
                    </div>

                    <QuiBox display="flex" gap="sm">
                        {MetadataComponent && generatorSetupValue === 'Synthesis' && (
                            <QuiButton
                                onClick={() => setShowOptions((current) => !current)}
                                variant={showOptions ? 'primary' : 'outline-default'}
                                size={'sm'}
                                type={'button'}
                                iconRight={showOptions ? 'chevron-up' : 'chevron-down'}
                            >
                                Options
                            </QuiButton>
                        )}
                        <QuiSegmentedControlField size="sm" name={`generatorSetup.${entity.value}`}>
                            <QuiSegmentedControlButton style={{ backgroundColor: 'transparent' }} value="Synthesis">
                                Synthesis
                            </QuiSegmentedControlButton>
                            <QuiSegmentedControlButton style={{ backgroundColor: 'transparent' }} value="Redaction">
                                Redaction
                            </QuiSegmentedControlButton>
                            <QuiSegmentedControlButton style={{ backgroundColor: 'transparent' }} value="Off">
                                Off
                            </QuiSegmentedControlButton>
                        </QuiSegmentedControlField>
                        <RegexListFieldModal initialEntityType={entity.value} listType="labelAllowLists" buttonIcon={QuiIconEnum.PlusCircle} />
                        <RegexListFieldModal initialEntityType={entity.value} listType="labelBlockLists" buttonIcon={QuiIconEnum.PlusCircle} />
                    </QuiBox>
                </QuiBox>
                {showOptions && generatorSetupValue == 'Synthesis' && MetadataComponent ? (
                    <MetadataComponent piiType={entity.value} name={`datasetGeneratorMetadata.${entity.value}`} automaticallySubmit />
                ) : null}
            </QuiBox>
        </QuiBox>
    );
}
