import { QuiBox, QuiButtonLink, QuiFlexBoxColumn, QuiIcon } from '@tonicai/ui-quinine';
import { PageTitle } from '../PageTitle/PageTitle';
import { CurrentUserAvatar } from '../CurrentUserAvatar/CurrentUserAvatar';
import { useAtomValue } from 'jotai';
import { currentUserAtom } from '../../stores/auth';
import { CopyToClipboardButton } from '../CopyToClipboardButton/CopyToClipboardButton';
import { UploadFilesButton } from './UploadFilesButton';
import { RunPipelineButton } from './RunPipelineButton';
import { usePipelineContext } from './usePipelineContext';
import { useFetchedBillingData, useHasActivePlan, useIsHostedProd, useLicenseDetails } from '@/stores';
import { UpgradePlanButton } from '@/pages/Billing/UpgradePlanButton';
import { LearnMoreButton } from '@/pages/Billing/LearnMoreButton';
import { BillingGuard } from '@/pages/Billing/BillingGuard';
import { NextSteps } from './NextSteps';

export function PipelineSidebar() {
    const { parseJobConfig } = usePipelineContext();
    const currentUser = useAtomValue(currentUserAtom);
    const isHostedProd = useIsHostedProd();
    const hasActivePlan = useHasActivePlan();
    const licenseDetails = useLicenseDetails();
    const fetchedBillingData = useFetchedBillingData();

    return (
        <QuiFlexBoxColumn gap="md">
            <PageTitle
                icon="git-branch"
                to={`/pipelines/${parseJobConfig.id}`}
                title={
                    <QuiFlexBoxColumn gap="xs">
                        <QuiBox overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {parseJobConfig.name}
                        </QuiBox>
                        <QuiBox display="flex" alignItems="center" gap="xs">
                            <div>
                                <CurrentUserAvatar size="sm" />
                            </div>
                            {currentUser?.displayName ? (
                                <QuiBox
                                    color="text-secondary"
                                    text="text-xs"
                                    flexGrow="1"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    whiteSpace="nowrap"
                                >{`Created by ${currentUser?.displayName}`}</QuiBox>
                            ) : null}
                        </QuiBox>
                    </QuiFlexBoxColumn>
                }
            >
                <QuiBox justifyContent="space-between" text="mono-text-xs" color="text-secondary" display="flex" gap="sm" alignItems="center">
                    <span data-test="pipeline-config-id">{parseJobConfig.id}</span>
                    <CopyToClipboardButton icon="clipboard" content={parseJobConfig.id} size="xs" />
                </QuiBox>
            </PageTitle>

            <QuiBox display="flex" gap="sm">
                <QuiBox display="flex" flexGrow="1" flexDirection="column">
                    {parseJobConfig.useInternalBucket ? <UploadFilesButton /> : <RunPipelineButton />}
                </QuiBox>
                <QuiButtonLink to={`/pipelines/${parseJobConfig.id}/settings`} iconLeft="settings" />
            </QuiBox>
            <NextSteps />

            <BillingGuard>
                {fetchedBillingData && isHostedProd && !hasActivePlan && licenseDetails?.category === 'FreeTrial' ? (
                    <QuiFlexBoxColumn padding="md" gap="md" border="border-default" bg="background-primary">
                        <QuiBox display="flex" alignItems="center" gap="md">
                            <QuiBox bg="green-400" display="inline-block" color="white" borderRadius="md" padding="xs">
                                <QuiIcon icon="flag" size="lg" />
                            </QuiBox>

                            <QuiBox text="text-sm" weight="medium">
                                Upgrade to Pay As You Go
                            </QuiBox>
                        </QuiBox>
                        <QuiBox>
                            <QuiBox text="text-sm" weight="medium">
                                Unlock Textual by upgrading
                            </QuiBox>
                            <QuiBox color="text-secondary" text="text-sm">
                                Unlock unlimited words scanned for just $0.89 per 1,000 words.
                            </QuiBox>
                        </QuiBox>

                        <QuiBox display="flex" gap="lg">
                            <UpgradePlanButton />
                            <LearnMoreButton />
                        </QuiBox>
                    </QuiFlexBoxColumn>
                ) : null}
            </BillingGuard>
        </QuiFlexBoxColumn>
    );
}
