import { useDatasetContext } from '@/contexts/DatasetContext';
import usePrevious from '@/hooks/usePrevious';

import {
    previouslyProcessedFilesAtom,
    processingFilesAtom,
    setPreviouslyProcessedFilesAtom,
    setProcessingFilesAtom,
    uploadingFilesAtom,
} from '@/stores';
import { JobStatus } from '@/types';
import { useQuiToasts } from '@tonicai/ui-quinine';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

export default function useFileProcessingCount() {
    const addToast = useQuiToasts();
    const { dataset } = useDatasetContext();
    const processingFiles = useAtomValue(processingFilesAtom);
    const previouslyProcessedFiles = useAtomValue(previouslyProcessedFilesAtom);
    const getProcessingFiles = () =>
        dataset?.files.filter(({ processingStatus }) => [JobStatus.QUEUED, JobStatus.RUNNING].includes(processingStatus));
    const getProcessedFiles = () =>
        dataset?.files.filter(({ processingStatus }) => ![JobStatus.QUEUED, JobStatus.RUNNING].includes(processingStatus));
    const previouslyProcessedFileCount = previouslyProcessedFiles.length;
    const processingFileCount = processingFiles.length;
    const processedFileCount = (getProcessedFiles()?.length ?? 0) - previouslyProcessedFileCount;
    const isProcessingFiles = processingFileCount > 0 && processedFileCount < processingFileCount;

    const uploadingFiles = useAtomValue(uploadingFilesAtom);
    const [uploadingFileCount, setUploadingFileCount] = useState(0);
    const uploadedFileCount = uploadingFileCount - uploadingFiles.length;
    const isUploadingFiles = uploadingFileCount > 0 && uploadedFileCount < uploadingFileCount;
    const previousIsUploadingFiles = usePrevious(isUploadingFiles);

    useEffect(() => {
        const preventPageExit = (event: BeforeUnloadEvent) => {
            event.preventDefault();
        };
        if (isUploadingFiles) {
            window.addEventListener('beforeunload', preventPageExit);
        }
        return () => {
            window.removeEventListener('beforeunload', preventPageExit);
        };
    }, [isUploadingFiles]);

    useEffect(() => {
        if (!isUploadingFiles && previousIsUploadingFiles) {
            addToast({
                title: 'Upload complete. You can leave the page while the files are processed.',
            });
        }

        if (isUploadingFiles && !previousIsUploadingFiles) {
            addToast({
                title: 'Uploading files. If you leave the page, the upload will stop.',
            });
        }
    });

    useEffect(() => {
        // Initialize the atom with files that were already processing from a prior upload after a page refresh
        setProcessingFilesAtom(() => getProcessingFiles()?.map((f) => new File([''], f.fileName)) ?? []);
        setPreviouslyProcessedFilesAtom(() => getProcessedFiles()?.map((f) => new File([''], f.fileName)) ?? []);
        // eslint-disable-next-line
    }, []);

    return {
        isUploadingFiles,
        uploadedFileCount,
        uploadingFileCount,
        setUploadingFileCount,
        isProcessingFiles,
        getProcessingFiles,
        getProcessedFiles,
        previouslyProcessedFileCount,
        processedFileCount,
        processingFileCount,
    };
}
