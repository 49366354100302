import { Auth } from '@/components/Auth/Auth';
import { useAtomValue } from 'jotai';
import { showOnboardingSurveyAtom } from '@/stores';
import { OnboardingSurveyDialog } from '../Home/OnboardingSurveyDialog';

export function OnboardingSurvey() {
    const shouldShowSurvey = useAtomValue(showOnboardingSurveyAtom);

    return (
        <Auth style={{ maxWidth: '580px' }}>
            <OnboardingSurveyDialog shouldShowSurvey={shouldShowSurvey} />
        </Auth>
    );
}
